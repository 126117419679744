import "./TermSelection.css";

import BackgroundImageLarge from "../assets/images/banner-1.jpg";
import BackgroundImageMedium from "../assets/images/banner-1@0.5x.jpg";
import BackgroundImageSmall from "../assets/images/banner-1@0.25x.jpg";

import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import { selectTerm } from "../store/reducers/applicationInfo";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import * as Typography from "../components/typography";
import { Button } from "../components/Button";

import { TermData, ApplicationTerms } from "../models/TermData";
import LoadingWheel from "../components/LoadingWheel";
import ModalLayout from "./ModalLayout";

type CombinedProps = PropsFromRedux & ApplicationTerms & {};

const TermSelection: React.FC<CombinedProps> = (props) => {
  const { terms, AppTermData, selectTerm } = props;
  const navigate = useNavigate();

  const items = (AppTermData.AppTermDataEntity as ApplicationTerms).terms.map(
    (item, index) => {
      return false;
    }
  );
  const [stateLoading, setStateLoading] = useState(items); // show loading spinner for selected Term only

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    if (AppTermData.error) {
      toastr.error(AppTermData.error.title, AppTermData.error.message);
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    AppTermData.AppTermDataEntity,
    AppTermData.loading,
    AppTermData.error,
    navigate,
  ]);

  const onSubmit = async (term: TermData, index: number) => {
    let currentState = stateLoading;
    currentState[index] = true;
    setStateLoading(currentState);
    selectTerm(term);
    navigate("/" + term.page);
  };

  return (
    <ModalLayout
      imageSrcSet={`${BackgroundImageLarge} 3600w, ${BackgroundImageMedium} 1800w, ${BackgroundImageSmall} 900w`}
      imageSizes="(max-width: 600px) 900px, (max-width: 1200px) 1800px, 3600px"
      imageAlt="Select Term Background"
    >
      <Typography.Label centered>Select Term</Typography.Label>
      {terms.map((term, index) => (
        <div key={term.termcode} style={{ position: "relative" }}>
          <Button
            onClick={() => onSubmit(term, index)}
            size="large"
            disabled={AppTermData.loading === "pending"}
          >
            {term.admissionTerm}
          </Button>

          <LoadingWheel
            numberOfDivs={3}
            showLoading={
              AppTermData.loading === "pending" && stateLoading[index]
            }
            spinnerOnly={true}
          />
        </div>
      ))}
    </ModalLayout>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
  };
};

const mapDispatch = {
  selectTerm,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(TermSelection);
