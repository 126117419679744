import React from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import PageBorder from "../components/PageBorder";
import ApplicationFooter from "../components/ApplicationFooter";
import { TermData as Props } from "../models/TermData";

import "./ApplicationCancelled.css";

export const ApplicationCancelled: React.FC<Props> = (props) => {
  const { cancelledState, declineSurveyUrl, declineSurveyParameters } = props;

  const renderCancelledStateTextMain = () => {
    switch (cancelledState) {
      case "cancelled":
        return <>Your application has been canceled.</>;
      case "declined":
        return <>You have declined your admission offer.</>;
      case "rescinded":
        return <>Your application has been withdrawn by UC Merced.</>;
    }
  };

  const renderDeclineSurveyParameters = declineSurveyParameters.map(
    (declineSurveyParameter) => {
      return (
        <input
          type="hidden"
          name={declineSurveyParameter.formData}
          value={declineSurveyParameter.formValue}
        />
      );
    }
  );

  const renderCancelledStateTextSub = () => {
    switch (cancelledState) {
      // case 'cancelled':
      // case 'rescinded':
      case "declined":
        return (
          <>
            <form action={declineSurveyUrl ?? ""} method="post" target="_blank">
              {renderDeclineSurveyParameters}
              We would appreciate it if you would take a moment to complete a
              brief&nbsp;
              {/* eslint-disable-next-line */}
              <a className="surveyLink">
                <button type="submit">survey</button>
              </a>
              .{" "}
            </form>
          </>
        );
    }
  };
  return (
    <>
      <PageBorder color="red" />
      <MessagingRow
        messaging={
          <div className="ApplicationAccepted-messaging">
            <Typography.PrimaryHeading>
              {renderCancelledStateTextMain()}
            </Typography.PrimaryHeading>
            <Typography.Paragraph>
              {renderCancelledStateTextSub()}
            </Typography.Paragraph>
          </div>
        }
      />
      <ApplicationFooter {...props} />
    </>
  );
};
